import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/font.ts\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/font.ts\",\"import\":\"Dancing_Script\",\"arguments\":[{\"weight\":\"500\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"dancingScript\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/font.ts\",\"import\":\"Newsreader\",\"arguments\":[{\"weight\":\"400\",\"style\":\"italic\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"newsReader\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/about/arabesque.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/about/curved-arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/book/nav-arrow.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/book/passport.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/contact/or.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/chat-bubble.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/check-list.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/invoice.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/money-hand.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/plane-book.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/plane.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/planning-check.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/puzzle.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/quote.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/speed-clock.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/home/trust.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/menu.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/icons/price/divider.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/logo.svg");
